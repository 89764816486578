export const environment = {
    production: false,
    urlApi: 'http://localhost:8080/api/v1/',
    //urlApi: 'http://187.189.251.166/api/sistema_administrativo_gestocar/v1/',
    ckKey:'2iSH067FsOYR5YJaaPT22YAsp93AOnKLQ2We890C',
    X_API_KEY: 'API_KEY_SASCE_uE5CG5Qg6wFsnIfpG6RlGzSNkH4XOv9eKpqJUtjoN7utV9Zshu',
    cryptoKey: 'gestocar_cryptokeyhTI4FSeH8dHyiQa5jhszoentc14cSN7viM4TbfdOYyBTeL',
    firebase: {
        apiKey: "AIzaSyBV56TMPcRJNJK4ZJBPJ8vI3WGeozLm0gA",
        authDomain: "sasce-23d89.firebaseapp.com",
        projectId: "sasce-23d89",
        storageBucket: "sasce-23d89.appspot.com",
        messagingSenderId: "580341875765",
        appId: "1:580341875765:web:0058dbc2eec511ee1b1d7c"
       // measurementId: "G-HD5MK01FQG"
    }
}